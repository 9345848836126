import React from 'react'
import { makeStyles } from '@material-ui/core'
import {
  Button,
  IconButton, Icon, ListItemIcon,
  Menu, MenuItem,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
  }
}))

export default function ClientContextMenu(props) {
  const classes = useStyles();
  const { menu } = props;

  return(
    <React.Fragment>
      <Menu
        id="simple-menu"
        anchorEl={menu.anchor}
        keepMounted
        open={Boolean(menu.anchor)}
        onClose={props.onMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={(e) => props.onClick('delete')}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Delete
        </MenuItem>
        <MenuItem onClick={(e) => props.onClick('info')}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          Info
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}
