import { accessToken } from '../utils/authorize'
import { API_BASE } from '../consts'

export function updateApplication(applicationId, params) {
  return fetch(`${API_BASE}/applications/${applicationId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': accessToken()
    },
    body: JSON.stringify(params)
  })
    .then(res => res.json())
}
