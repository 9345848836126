import React from 'react'
import { makeStyles, Container, Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
  }
}))

export default function (props) {
  const classes = useStyles();

  return(
    <React.Fragment>
      <Container>
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="h4" style={{marginTop: 16}} gutterBottom>Support</Typography>
            <Typography variant="h5" style={{marginTop: '0'}} gutterBottom>Telegram</Typography>
            <Typography variant="body1" gutterBottom>Contact us in Telegram via <a href="https://t.me/taas5">@taas5</a>. You will get very quick reply there.</Typography>
            <Typography variant="body1" gutterBottom>Follow our news and update in Telegram channel <a href="https://t.me/taas_news">@taas_news</a>.</Typography>
            <Typography variant="h5" style={{marginTop: '0'}} gutterBottom>Email</Typography>
            <Typography variant="body1" gutterBottom>You can also contact us via email <a href="mailto:support@tgtgtg.ru">support@tgtgtg.ru</a>.</Typography>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}
