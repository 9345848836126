import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
  }
}))

export default function FormattedInput(props) {
  const classes = useStyles();
  const { InputComponent, inputProps, formatFunction, value } = props;
  const [start, setStart] = React.useState()
  const [end, setEnd] = React.useState()
  const inputEl = React.useRef(null)
  const nonChangedSwitch = React.useRef(false)

  function handleChange(e) {
    let value = e.target.value;
    if (formatFunction)
      value = formatFunction(value);
    // setStart(inputEl.current.selectionStart - 1);
    // setEnd(inputEl.current.selectionEnd - 1);
    // props.onChange(nextValue);
    props.onChange(value);
    // if (formattedValue === value) {
    //   nonChangedSwitch.current = true
    // }
  }

  // Try to paste in 'jasdjhsd'

  // function handleKeyDown(e) {
  //   console.log(e);
  //   // console.log(e.key, formatFunction(e.key))
  //   if (formatFunction(e.key) === '') {
  //     e.preventDefault();
  //   }
  // }

  // React.useEffect(() => {
  //   if (inputEl.current) {
  //     if (nonChangedSwitch.current) {
  //       console.log('gataka');
  //       inputEl.current.querySelector('input').setSelectionRange(start, end)
  //       nonChangedSwitch.current = false
  //     }
  //   }
  // })

  return(
    <InputComponent {...inputProps}
      onChange={handleChange}
      ref={inputEl}
      value={value}
      >
    </InputComponent>
  )
}
