import React from 'react';
import { withRouter } from 'react-router'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { API_BASE } from '../consts'
import { accessToken } from '../utils/authorize'
import FormattedInput from './FormattedInput';
import { Grid, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Link, InputLabel, Select, MenuItem, FormHelperText, Icon, Tooltip, Typography,
  Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

function NewApplicationDialog(props) {
  const [phone, setPhone] = React.useState("");
  const [name, setName] = React.useState("");
  const [code, setCode] = React.useState("")
  const [password, setPassword] = React.useState("");
  const [apiId, setApiId] = React.useState("");
  const [apiHash, setApiHash] = React.useState("");
  const [loading, setLoading] = React.useState(false)
  const [step, setStep] = React.useState(-1)
  const [application, setApplication] = React.useState(null)
  const [useMessageDB, setUseMessageDB] = React.useState(false);
  const [useChatInfoDB, setUseChatInfoDB] = React.useState(false);
  const [useFileDB, setUseFileDB] = React.useState(false);
  const [useSSD, setUseSSD] = React.useState(false);
  const [error, setError] = React.useState()
  const [errors, setErrors] = React.useState()
  const [tdlibVersion, setTdlibVersion] = React.useState("1.7.12");
  const [proxy, setProxy] = React.useState({});


  React.useEffect(() => {
    if (props.open) {
      fetch(`${API_BASE}/applications/l`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': accessToken()
        }
      }).then(res => res.json())
        .then(({api_id, api_hash}) => {
          if (api_hash) setApiHash(api_hash)
          if (api_id) setApiId(api_id)
        }).catch(err => {
        })
    }
  }, [props.open])

  function onPhoneChange(v) {
    setPhone(v)
  }

  function submit(e, botToken=false) {
    setLoading(true)
    fetch(`${API_BASE}/applications`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': accessToken()
      },
      body: JSON.stringify({
        phone: phone,
        is_bot_token: botToken,
        api_id: apiId,
        api_hash: apiHash,
        use_message_database: useMessageDB,
        use_chat_info_database: useChatInfoDB,
        use_file_database: useFileDB,
        use_ssd: useSSD,
        version: tdlibVersion,
        name: name,
        proxy_port: proxy.port,
        proxy_server: proxy.server,
        proxy_username: proxy.username,
        proxy_password: proxy.password
      })
    }).then(res => res.json())
      .then(application => {
        setLoading(false)
        if (application.errors) {
          setErrors(Object.values(application.errors))
          setStep(-1000)
        } else {
          props.onCreateClient(application)
          setApplication(application)
          if (application.state === 'wait_code') {
            setStep(1)
          } else {
            handleClose()
          }
        }
      }).catch(err => {
        setLoading(false)
      })
    e.preventDefault()
  }

  function submitBotToken(e) {
    submit(e, true);
  }

  function onCodeChange(e) {
    setCode(e.target.value)
  }

  function onPasswordChange(e) {
    setPassword(e.target.value)
  }

  function handleClose() {
    props.handleClose()
    setStep(-1)
    setCode('')
    setPhone('')
    setUseMessageDB('')
    setUseChatInfoDB('')
    setUseFileDB('')
    setName('')
    setError(null)
    setErrors(null)
    setProxy({})
  }

  function submitCode(e) {
    setLoading(true)
    setError(null)
    fetch(`${API_BASE}/client/code`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        api_key: application.auth_key,
        code: code
      })
    })
    .then(res => res.json())
    .then(res => {
      if (res.state === 'ready') {
        handleClose()
      } else if (res.state === 'wait_password') {
        setStep(3)
      } else {
        setError(res.error)
      }
      setLoading(false)
    })
    .catch(res => {
      setLoading(false)
    })

    e.preventDefault();
  }

  function submitPassword(e) {
    setLoading(true)
    fetch(`${API_BASE}/client/password`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        api_key: application.auth_key,
        password: password
      })
    })
      .then(res => res.json())
      .then(res => {
        if (res.state === 'ready') {
          handleClose()
        } else {
          setError(res.error)
        }
        setLoading(false)
      })
      .catch(res => {
        setLoading(false)
      })

    e.preventDefault();
  }

  const commonContent = <React.Fragment>
    <div>
      <TextField
        margin="dense"
        id="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        label="Name"
        helperText="Set some name to remember why you need this client"
        style={{
          marginRight: '16px'
        }}
      />
    </div>
    <TextField
      margin="dense"
      id="api_id"
      value={apiId}
      onChange={(e) => setApiId(e.target.value)}
      required
      label="App api_id"
      style={{
        marginRight: '16px'
      }}
    />
    <TextField
      margin="dense"
      id="api_hash"
      value={apiHash}
      onChange={(e) => setApiHash(e.target.value)}
      required
      label="App api_hash"
    />
    <FormControl style={{ marginTop: 16 }}>
      <FormLabel>TDLib options</FormLabel>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={useMessageDB}
              onChange={(e) => setUseMessageDB(e.target.checked)}
              color="primary"
            />
          }
          label="Use Message Database (check if you need to preserve info about chats between client restarts*)"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={useChatInfoDB}
              onChange={(e) => setUseChatInfoDB(e.target.checked)}
              color="primary"
            />
          }
          label="Use Chat Info Database (check if you need to preserve info about chats between client restarts*)"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={useFileDB}
              onChange={(e) => setUseFileDB(e.target.checked)}
              color="primary"
            />
          }
          label="Use File Database (check if you need to preserve info about files between client restarts*)"
        />
        {/* <FormControlLabel
          control={
            <Checkbox
              checked={useSSD}
              onChange={(e) => setUseSSD(e.target.checked)}
              color="primary"
            />
          }
          label="SSD Drive (5x times speed up, $3.79 per month per client)"
        /> */}
      </FormGroup>
    </FormControl>
    <div>* Client can occasionally restart</div>
    <div>Rule of thumb - if you going to work with chats you are joined in - don't check any "use DB" options. If you are going to find new chats constantly and not join them - check use chat info. No checks gets you very massive performance boost.</div>
    <div><Link color="secondary" href="https://core.telegram.org/tdlib/docs/classtd_1_1td__api_1_1tdlib_parameters.html">Read more about TDLib parameters</Link></div>
    <FormControl style={{ width: '180px' }}>
      <InputLabel>
        <Grid container direction="row" alignItems="center">
          TDLib Version <Tooltip style={{marginLeft: 6}} title=""><Icon>info</Icon></Tooltip>
        </Grid></InputLabel>
      <Select
        value={tdlibVersion}
        onChange={(e) => setTdlibVersion(e.target.value)}
      >
        <MenuItem value="1.7.12">1.7.12</MenuItem>
      </Select>
    </FormControl>
    <Accordion style={{marginTop: 16}}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="body2">Proxy Settings</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <TextField
            margin="dense"
            id="port"
            value={proxy.port}
            onChange={(e) => setProxy({ ...proxy, port: e.target.value}) }
            label="Proxy Port"
            style={{
              marginRight: '16px'
            }}
          />
          <TextField
            margin="dense"
            id="server"
            value={proxy.server}
            onChange={(e) => setProxy({ ...proxy, server: e.target.value })}
            label="Proxy Server"
            style={{
              marginRight: '16px'
            }}
          />
          <TextField
            margin="dense"
            id="username"
            value={proxy.username}
            onChange={(e) => setProxy({ ...proxy, username: e.target.value })}
            label="Proxy Username"
            style={{
              marginRight: '16px'
            }}
          />
          <TextField
            margin="dense"
            id="password"
            value={proxy.password}
            onChange={(e) => setProxy({ ...proxy, password: e.target.value })}
            label="Proxy Password"
            style={{
              marginRight: '16px'
            }}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  </React.Fragment>

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        { step === -1 &&
          <React.Fragment>
            <DialogTitle id="form-dialog-title">New Client</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Choose whether you want to use phone number or bot token.
                <div style={{textAlign: 'center'}}>
                  <Button fullWidth onClick={() => setStep(0)}>
                    Phone Number
                  </Button>
                  <Button fullWidth onClick={() => setStep(2)}>
                    Bot Token
                  </Button>
                </div>
              </DialogContentText>
            </DialogContent>
          </React.Fragment>
        }
        {step === -1000 &&
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Error!</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <p>Failed to create client with error(s):</p>
                {errors && errors.map(err => <div>
                  <p>{err}</p>
                </div>)
                }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>
                OK
              </Button>
            </DialogActions>
          </React.Fragment>
        }
        { step === 0 &&
          <form onSubmit={submit}>
            <DialogTitle id="form-dialog-title">New Client</DialogTitle>
            <DialogContent>
              <DialogContentText>
              Enter a phone number that the new Telegram client will us, and api_id and api_hash. <Link color="secondary" href="https://core.telegram.org/api/obtaining_api_id">How to get api_id and api_hash</Link>.
              </DialogContentText>
              <FormattedInput
                InputComponent={TextField}
                inputProps={{
                  autoFocus: true,
                  margin: "dense",
                  id: "phone",
                  label: "Phone Number",
                  type: "tel",
                  helperText: "Enter only digits",
                  fullWidth: true
                }}
                value={phone}
                required
                onChange={onPhoneChange}
                formatFunction={(v) => v.replace(/[^0-9]/g, '')}
              />
              {commonContent}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button disabled={(phone.length === 0 || apiHash.length === 0 || apiId.length === 0) || loading} type="submit" variant="contained" color="primary">
                {loading && <CircularProgress size={20} style={{marginRight: '8px'}} />}
                Create
              </Button>
            </DialogActions>
          </form>
        }
        { step == 1 &&
          <form onSubmit={submitCode}>
            <DialogTitle id="form-dialog-title">New Client</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Enter a security code sent to {application.phone}
                {error && <div><p>Error: </p>
                  <p>{error}</p>
                </div>
                }
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="code"
                label="Security code"
                value={code}
                onChange={onCodeChange}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button disabled={code.length === 0 || loading} onClick={submitCode} variant="contained" color="primary">
                {loading && <CircularProgress size={20} style={{marginRight: '8px'}} />}
                Submit
              </Button>
            </DialogActions>
          </form>
        }
        { step === 2 &&
          <form onSubmit={submitBotToken}>
            <DialogTitle id="form-dialog-title">New Client</DialogTitle>
            <DialogContent>
              <DialogContentText>
              Enter a bot token that the new Telegram client will use and api_id and api_hash. <a href="https://core.telegram.org/api/obtaining_api_id">How to get api_id and api_hash</a>.
              </DialogContentText>
              <FormattedInput
                InputComponent={TextField}
                inputProps={{
                  autoFocus: true,
                  margin: "dense",
                  id: "token",
                  label: "Bot Token",
                  fullWidth: true
                }}
                value={phone}
                required
                onChange={onPhoneChange}
              />
              {commonContent}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button disabled={(phone.length === 0 || apiHash.length === 0 || apiId.length === 0) || loading} type="submit" variant="contained" color="primary">
                {loading && <CircularProgress size={20} style={{marginRight: '8px'}} />}
                Create
              </Button>
            </DialogActions>
          </form>
        }
        {step == 3 &&
          <form onSubmit={submitPassword}>
            <DialogTitle id="form-dialog-title">New Client</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Enter your cloud password
                {error && <div><p>Error: </p>
                  <p>{error}</p>
                </div>
                }
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="password"
                type="password"
                label="Cloud Password"
                value={password}
                onChange={onPasswordChange}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button disabled={password.length === 0 || loading} type="submit" variant="contained" color="primary">
                {loading && <CircularProgress size={20} style={{ marginRight: '8px' }} />}
                Submit
              </Button>
            </DialogActions>
          </form>
        }
      </Dialog>
    </div>
  );
}

export default withRouter(NewApplicationDialog)
