import React from 'react'
import { makeStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
  }
}))

export default function InfoDialog(props) {
  const classes = useStyles();
  const { open, client={} } = props;

  function handleClose() {
    props.onClose();
  }

  return(
    <Dialog open={open} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Information</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <p><b>App api_id</b> {client.api_id}</p>
          <p><b>App api_hash</b> {client.api_hash}</p>
          <p><b>App api_hash</b> {client.version}</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}
