import React from 'react'
import { makeStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
  }
}))

export default function DeleteDialog(props) {
  const classes = useStyles();
  const { open, deleting } = props;

  function handleClose() {
    props.onClose();
  }


  return(
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Delete the client?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <p>You will not be able to use that API Key anymore. This will also delete all your data associated with the key.</p>
          <p><b>This action can not be undone. Are you sure you want to continue?</b></p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={deleting} variant="contained" onClick={props.onDelete} color="primary">
          Delete
        </Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
