import React from 'react'
import { makeStyles } from '@material-ui/core'
import { API_BASE } from '../consts'
import { Link, Container, Grid, Typography, LinearProgress, TextField, MenuItem, Button } from '@material-ui/core'
import qs from 'query-string';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
  },
  field: {
    fontWeight: '500'
  },
  textarea: {
    width: '100%',
    height: '250px',
    fontFamily: "Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New, monospace",
    fontSize: '14px'
  },
  errorResult: {
    color: theme.palette.error.main
  },
  resultPre: {
    wordBreak: "break-all",
    whiteSpace: "pre",
    maxHeight: '400px',
    overflow: 'scroll',
    backgroundColor: 'white',
    padding: '16px'
  }
}))

const presets = {
  getChats: {
    body: `{
  "api_key": "YOUR_API_KEY",
  "@type": "getChats",
  "limit": "100",
  "offset_order": "9223372036854775807"
}`,
    method: 'POST',
    url: `${API_BASE}/client`
  },
  getChatHistory: {
    body: `{
  "api_key": "YOUR_API_KEY",
  "@type": "getChatHistory",
  "chat_id": "",
  "limit": "100",
  "offset": "0",
  "from_message_id": "0"
}`,
    method: 'POST',
    url: `${API_BASE}/client`
  },
  sendCode: {
    body: `{
  "api_key": "YOUR_API_KEY",
  "code": "22806"
}`,
    method: 'POST',
    url: `${API_BASE}/client/code`
  },
  sendCloudPwd: {
    body: `{
  "api_key": "YOUR_API_KEY",
  "password": "PASSWORD"
}`,
    method: 'POST',
    url: `${API_BASE}/client/password`
  },
  sendMessage: {
    body: `{
  "api_key": "YOUR_API_KEY",
  "@type": "sendMessage",
  "chat_id": "",
  "disable_notification": true,
  "input_message_content": {
    "@type": "inputMessageText",
    "disable_web_page_preview": false,
    "text": {
      "@type": "formattedText",
      "text": "Hello world"
    }
  }
}`,
    method: 'POST',
    url: `${API_BASE}/client`
  },
  searchPublicChat: {
    body: `{
  "api_key": "YOUR_API_KEY",
  "@type": "searchPublicChat",
  "username": "@username"
}`,
    method: 'POST',
    url: `${API_BASE}/client`
  },
  createSubscription: {
    body: `{
  "api_key": "YOUR_API_KEY",
  "hook_url": "https://example.com",
  "trigger": "new_message"
}`,
    method: 'POST',
    url: `${API_BASE}/subscriptions`
  },
  listSubscriptions: {
    body: `{
  "api_key": "YOUR_API_KEY"
}`,
    method: 'GET',
    url: `${API_BASE}/subscriptions`
  },
  deleteSubscription: {
    body: `{
  "api_key": "YOUR_API_KEY"
}`,
    method: 'DELETE',
    url: `${API_BASE}/subscriptions/new_message`
  },
//   fullChatHistory: {
//     body: `{
//   "api_key": "YOUR_API_KEY",
//   "chat_id": ""
// }`,
//     method: 'GET',
//     url: `${API_BASE}/client/get_full_chat_history`
//   },
}

export default function (props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(presets.getChats.body)
  const [url, setUrl] = React.useState(presets.getChats.url)
  const [method, setMethod] = React.useState(presets.getChats.method)
  const [error, setError] = React.useState()
  const [result, setResult] = React.useState()
  const [loading, setLoading] = React.useState()
  const [open, setOpen] = React.useState(props.open)
  const {
    autoFocus=true
  } = props;

  React.useEffect(() => {
    if (props.clickedClient) {
      setValue(value => value.replace(/"api_key": ".*?"/, `"api_key": "${props.clickedClient.auth_key}"`))
    }
  }, [props.clickedClient])

  function handlePresetSelect(preset) {
    setValue(presets[preset].body.replace(/"api_key": ".*?"/, value.match(/"api_key": ".*?"/)))
    setUrl(presets[preset].url)
    setMethod(presets[preset].method)
  }

  function handleRequest() {
    let request
    setError(null)
    setResult(null)
    setLoading(true)

    try {
      if (method == 'GET') {
        let _url = url + '?' + qs.stringify(JSON.parse(value), {arrayFormat: 'brackets'})
        request = fetch(_url)
      } else {
        request = fetch(url, {
          method: method,
          headers: {
            'Content-Type': 'application/json',
          },
          body: value
        })
      }
      request.then(response => response.json()).then(response => {
        setResult(JSON.stringify(response, null, 2))
        setLoading(false)
      }).catch(error => {
        setError(error)
        setLoading(false)
      })
    } catch(e) {
      setError(e)
      setLoading(false)
    }
  }

  return(
    <React.Fragment>
      <Grid item xs={12}>
        {!open && <React.Fragment>
          <Typography variant="body" gutterBottom>Try using our API Playground for performing requests.</Typography>
          <Button onClick={() => setOpen(true)}>Open API Playground</Button>
        </React.Fragment>}
        {open && <React.Fragment>
          <Typography variant="h5" className={classes.field} gutterBottom style={{marginTop: 16}}>API Playground</Typography>
          <Typography variant="body" gutterBottom>{props.description}</Typography>
          <div>
            <Button onClick={() => handlePresetSelect('getChats')}>Get Chats</Button>
            <Button onClick={() => handlePresetSelect('sendCode')}>Send Authentication Code</Button>
            <Button onClick={() => handlePresetSelect('sendCloudPwd')}>Send Cloud Password</Button>
            <Button onClick={() => handlePresetSelect('getChatHistory')}>Get Chat History</Button>
            <Button onClick={() => handlePresetSelect('searchPublicChat')}>Search Public Chat</Button>
            <Button onClick={() => handlePresetSelect('sendMessage')}>Send Message</Button>
            <Button onClick={() => handlePresetSelect('createSubscription')}>Create Subscription</Button>
            <Button onClick={() => handlePresetSelect('listSubscriptions')}>List Subscriptions</Button>
            <Button onClick={() => handlePresetSelect('deleteSubscription')}>Delete Subscription</Button>
            {/* <Button onClick={() => handlePresetSelect('fullChatHistory')}>Full Chat History</Button> */}
          </div>
          <Grid container spacing={2}>
            <Grid item xs={2} style={{ display: 'inline-flex' }}>
              <TextField
                labelId="method-select-label"
                id="method-select"
                value={method}
                label="Method"
                onChange={(e) => setMethod(e.target.value)}
                fullWidth
                select
                style={{ alignSelf: 'center' }}
              >
                <MenuItem value={'GET'}>GET</MenuItem>
                <MenuItem value={'POST'}>POST</MenuItem>
                <MenuItem value={'DELETE'}>DELETE</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={10}>
              <TextField
                autoFocus={autoFocus}
                margin="dense"
                style={{ alignSelf: 'center' }}
                error={url.length == 0}
                helperText={url.length == 0 ? 'Should not be empty' : ''}
                id="url"
                label="URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <Typography variant="h6" gutterBottom>Payload</Typography>
          <textarea
            onChange={(v) => setValue(v.target.value)}
            value={value}
            className={classes.textarea}/>
          <Button onClick={handleRequest} variant="contained">Run</Button>
          {loading && <Grid style={{marginTop: '8px'}} item xs={12}>
            <LinearProgress />
          </Grid>}
          {error && <div className={classes.errorResult}>
            <Typography variant="h5">Error</Typography>
            <Typography variant="body2">{error.message}</Typography>
          </div>}
          {result && <div style={{marginTop: 16}}>
            <Typography variant="body2">
              <Typography variant="h5">Result</Typography>
              <pre className={classes.resultPre}>
                {result}
              </pre>
            </Typography>
          </div>}
        </React.Fragment>}
      </Grid>
    </React.Fragment>
  )
}
