import React from 'react'
import { API_BASE } from '../consts'
import { accessToken } from '../utils/authorize'
import { updateApplication } from '../utils/requests'
import { Button, Checkbox, Collapse, makeStyles, TextField, Tooltip } from '@material-ui/core'
import {
  Grid, IconButton, Icon, Typography, Paper, Card, CardContent,
  InputAdornment
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteDialog from './DeleteDialog';
import InfoDialog from './InfoDialog';
import ClientContextMenu from './ClientContextMenu';
import ApplicationMetrics from './ApplicationMetrics';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
  },
  row: {
    marginTop: 0,
    marginBottom: 0,
    paddingBottom: '16px !important',
    paddingTop: '16px !important',
    cursor: 'pointer',
    paddingLeft: '16px !important',
    borderBottom: '1px solid #ccc',
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: '#eee',
      borderRadius: 32,
      borderBottom: 'none'
    }
  },
  authKey: {
    wordWrap: 'break-word',
    userSelect: 'all'
  },
  phone: {
    wordWrap: 'break-word',
  },
  usage: {
    paddingLeft: 13
  },
  iconButtonRoot: {
    padding: 0
  }
}))

export default function ClientsList(props) {
  const classes = useStyles();
  const { clients, onDelete } = props;
  const [menu, setMenu] = React.useState({});
  const [dialogOpen, setDialogOpen] = React.useState("");
  const [deleting, setDeleting] = React.useState(false);
  const [ metrics, setMetrics ] = React.useState();

  function handleMenuClick(e, client) {
    setMenu({anchor: e.currentTarget, client})
    e.stopPropagation()
  }

  function handleMenuClose() {
    setMenu({})
  }


  function handleDialogClose() {
    setMenu({})
    setDialogOpen("")
  }

  function handleContextMenuClick(dialog) {
    setDialogOpen(dialog)
  }

  function handleKeyDeleteClick() {
    setDeleting(true)
    fetch(`${API_BASE}/applications/${menu.client.id}`, {
      method: 'delete',
      headers: {
        'Accept': 'application/json',
        'Authorization': accessToken()
      }
    }).then(res => {
        onDelete(menu.client)
        handleDialogClose()
      })
      .finally(() => {
        setDeleting(false)
      })
  }

  const handleBulkDeleteClick = React.useCallback((ids) => {
    fetch(`${API_BASE}/applications/bulk`, {
      method: 'delete',
      headers: {
        'Accept': 'application/json',
        'Authorization': accessToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ids: ids
      })
    }).then(res => {
      window.refetchDashboard()
    })
    .finally(() => {
    })
  }, [props.clients])

  return(
    <React.Fragment>
      {clients && clients.length > 0 && <Grid item lg={12} xs={12} >
          <BulkAction style={{ marginTop: '8px' }} clients={clients} handleDelete={handleBulkDeleteClick} />
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}><div className={classes.field} style={{marginTop: '16px', marginLeft: '8px', marginBottom: '-8px'}}>Phone</div></Grid>
            <Grid item xs={6}><div className={classes.field} style={{marginTop: '16px', marginLeft: '0', marginBottom: '-8px'}}>API Key</div></Grid>
            {/* <Grid item xs={2}><div className={classes.field} style={{marginTop: '16px', marginLeft: '0', marginBottom: '-8px'}}>Current Usage (requests:webhooks)</div></Grid> */}
            <Grid item xs={2}><div className={classes.field} style={{marginTop: '16px', marginLeft: '0', marginBottom: '-8px'}}>Name</div></Grid>
          </Grid>
          <Grid container style={{marginTop: '8px'}} spacing={2}>
            {clients.map(client => <Row key={client.id} classes={classes} client={client} onClick={props.onClick} handleMenuClick={handleMenuClick}/> )}
          </Grid>
          <ClientContextMenu menu={menu} onMenuClose={handleMenuClose} onClick={handleContextMenuClick} />
          <DeleteDialog
            deleting={deleting}
            open={dialogOpen === 'delete'}
            onClose={handleDialogClose}
            onDelete={handleKeyDeleteClick}
          />
          <InfoDialog
            open={dialogOpen === 'info'}
            onClose={handleDialogClose}
            client={menu.client}
          />
        </Grid>
      }
    </React.Fragment>
  )
}

function Row(props) {
  const [expanded, setExpanded] = React.useState();
  const [selected, setSelected] = React.useState(false);
  const [metrics, setMetrics] = React.useState();
  const [loading, setLoading] = React.useState();
  const [saved, setSaved] = React.useState();
  const {client, classes, onClick, handleMenuClick} = props;

  const handleSelect = React.useCallback(
    () => {
      client.selected = !selected;
      setSelected(!selected);
      window.refreshBulkAction()
    },
    [ selected, client ],
  )

  const handleCbClick = React.useCallback(e => e.stopPropagation(), [])

  const rowStyle = client.fatal_at ? {
    backgroundColor: '#ffe6e6',
    borderRadius: '35px'
  } : {};

  return(
    <Grid key={client.id} container item lg={12} className={classes.row} style={rowStyle}>
      <Grid container item onClick={() => onClick(client)}>
        <Grid xs={1} item className={classes.none} >
          <Checkbox checked={selected} style={{ padding: 0, paddingRight: '8px' }} onClick={handleCbClick} onChange={handleSelect} />
          <IconButton size="small" style={{ padding: 0 }} onClick={() => setExpanded(!expanded)}>
            <Tooltip title="View details and metrics">
              <Icon>{expanded ? 'expand_less' : 'expand_more'}</Icon>
            </Tooltip>
          </IconButton>
        </Grid>
        <Grid xs={2} item className={classes.phone}>{client.phone}</Grid>
        <Grid xs={6} item className={classes.authKey}>{client.auth_key}</Grid>
        {/* <Grid xs={2} item className={classes.usage}>{client.current_usage && client.current_usage.requests_count}:{client.current_usage && client.current_usage.webhooks_count}</Grid> */}
        <Grid xs={2} item className={classes.usage}>
          <TextField
            onChange={(e) => {
              setSaved(null)
              client.name = e.target.value // to display in cache
              updateApplication(client.id, {
                name: e.target.value
              }).then(() => {
                setSaved(true)
                window.setTimeout(() => {
                  setSaved(false)
                }, 2000)
              })
            }}
            defaultValue={client.name}
            style={{marginBottom: '-8x', marginTop: '-8px'}}
            InputProps={{
              endAdornment: saved && <InputAdornment position="end">
                <span>Saved</span>
              </InputAdornment>
            }}

          ></TextField>
        </Grid>
        <Grid xs={1} item>
          <IconButton classes={{ root: classes.iconButtonRoot }} onClick={(e) => handleMenuClick(e, client)}>
            <MoreVertIcon />
          </IconButton>
        </Grid>
        <Collapse style={{width: '100%'}} unmountOnExit in={expanded}>
          <div>
            <Card variant="outlined" style={{marginBottom: '8px', marginTop: '8px'}}>
              <CardContent>
                <Typography variant="body1" gutterBottom>Client Details</Typography>
                <Typography variant="body2">TDLlib v{client.version}</Typography>
                <Typography variant="body2">Current usage: {client?.current_usage?.requests_count} requests / {client?.current_usage?.webhooks_count} webhooks</Typography>
                {client.subscriptions?.length > 0 && <Typography variant="body2">
                  Webhooks: {client.subscriptions.map(s => `${s.trigger} ${s.hook_url}`).join(', ')}
                </Typography>}
                {/* <TextField
                  onChange={(e) => {
                    setSaved(null)
                    client.name = e.target.value // to display in cache
                    updateApplication(client.id, {
                      name: e.target.value
                    }).then(() => {
                      setSaved(true)
                    })
                  }}
                  defaultValue={client.name}
                  helperText="Set some name to remember why you need this client"
                  InputProps={{
                    endAdornment: saved && <InputAdornment position="end">
                      <span>Saved</span>
                    </InputAdornment>
                  }}

                ></TextField> */}
              </CardContent>
            </Card>
          </div>
          <ApplicationMetrics app_id={client.id} />
        </Collapse>
      </Grid>
    </Grid>
  )
}

function BulkAction(props) {
  const [update, setUpdate] = React.useState(0);
  React.useEffect(() => {
    window.refreshBulkAction = function() {
      setUpdate(u => u + 1)
    }
  }, [])

  const sel = props.clients.filter(c => c.selected);
  const cnt = sel.length;
  const handleDelete = function() {
    props.handleDelete(sel.map(c => c.id))
  }
  const btn = <Button disabled={cnt == 0} onClick={handleDelete}>Bulk Delete ({cnt})</Button>
  return(<React.Fragment>
    {cnt == 0 && <Tooltip title="Select clients to bulk delete">
      {btn}
    </Tooltip>}
    {cnt > 0 && btn}
  </React.Fragment>)
}
