import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { API_BASE } from '../consts'
import { accessToken } from '../utils/authorize'
import { Link, Container, Grid, Typography, LinearProgress, TextField, Collapse } from '@material-ui/core'
import { Link as RouterLink, useHistory } from 'react-router-dom';
import ClientsList from './ClientsList';
import ApplicationMetrics from './ApplicationMetrics';
import DashboardNav from './DashboardNav';
import {
  Button,
  IconButton, Icon, ListItemIcon,
  Menu, MenuItem,
} from '@material-ui/core'
import NewApplicationDialog from './NewApplicationDialog'
import Playground from './Playground'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  btnCreate: {
    verticalAlign: 'baseline',
    marginLeft: theme.spacing(2)
  },
  field: {
    fontWeight: '500'
  },
  header: {
    marginTop: 8 * 6,
    marginBottom: 8,
    // paddingLeft: '16px !important'
  },
}));


export default function Dashboard(props) {
  const classes = useStyles();
  const [clients, setClients] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [statsOpen, setStatsOpen] = React.useState();
  const history = useHistory()
  const [newAppDialogOpen, setNewAppDialogOpen] = React.useState()
  const [clickedClient, setClickedClient] = React.useState()
  const [ update, setUpdate ] = React.useState(0);

  React.useEffect(() => {
    window.refetchDashboard = function () {
      setUpdate(u => u + 1)
    }
  }, [])

  React.useEffect(() => {
    fetch(`${API_BASE}/applications.json`, {
      headers: {
        'Authorization': accessToken()
      }
    }).then(res => res.json())
      .then(res => {
        setClients(res)
        setLoading(false)
      })

    fetch(`${API_BASE}/plan.json`, {
      headers: {
        'Authorization': accessToken()
      }
    }).then(res => res.json())
      .then(res => {
        // We will use it inside ApplicationMetrics to know what to show
        window.taasCurrentPlan = res.plan
      })
  }, [update])

  function handleNewAppClick() {
    setNewAppDialogOpen(true)
  }

  function handleClientClick(client) {
    setClickedClient({...client})
  }

  function handleClientCreate(application) {
    setClients(clients => {
      clients.unshift(application);
      return clients;
    })
  }

  function handleClientDelete(deletedClient) {
    setClients(clients => {
      return clients.filter((client) => client.id !== deletedClient.id);
    })
  }

  return(
    <div>
      <Container maxWidth="md">
        <DashboardNav />
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.header}>
            {clients.length > 0 && <div>
              <Typography variant="h5" className={classes.field}>Client Metrics <Button onClick={() => setStatsOpen(!statsOpen)}>{statsOpen ? "Hide" : "Show"}</Button></Typography>
              <Collapse in={statsOpen} unmountOnExit >
                <Grid container justify="center">
                  <Grid item lg={10}>{statsOpen && <ApplicationMetrics />}</Grid>
                </Grid>
              </Collapse>
            </div>}

          </Grid>
          <Grid item xs={12} className={classes.none}>
            <Typography variant="h5" className={classes.field}>Your Clients <Button variant="contained" onClick={handleNewAppClick} color="primary" startIcon={<Icon>add</Icon>} className={classes.btnCreate}>New Client</Button></Typography>

          </Grid>
          {clients.length > 0 && <Playground
            clickedClient={clickedClient}
            description="Click the client in the table below to insert the API key. Select the body preset by clicking a button below."
          />}
          <ClientsList
            clients={clients}
            onClick={handleClientClick}
            onDelete={handleClientDelete}
            />
          {!loading && (!clients || clients.length == 0) && <Grid item xs={12}>
            None. Create one using button above
          </Grid>}
          {loading && <Grid item xs={12}>
            <LinearProgress indeterminate />
          </Grid>}
          <NewApplicationDialog
            open={newAppDialogOpen}
            handleClose={() => setNewAppDialogOpen(false)}
            onCreateClient={handleClientCreate}
          />
        </Grid>
      </Container>
    </div>
  )
}
