import React from 'react'
import { AppBar, Toolbar, Typography, makeStyles, Link, Button } from '@material-ui/core'
import { Route, Link as RouterLink } from 'react-router-dom'
import logo from '../images/taas logo.png'

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
    display: 'inline-block',
    fontFamily: 'Roboto',
    verticalAlign: 'middle',
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block',
    },
  },
  taas: {
    fontFamily: 'Roboto',
    color: '#000',
    fontSize: '125%',
    [theme.breakpoints.down('md')]: {
      fontSize: '100%',
    }
  },
  other: {
    fontSize: '85%',
    marginLeft: '8px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  titleMobile: {
    flexGrow: 1,
    display: 'inline-block',
    fontFamily: 'Roboto',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  logo: {
    width: '40px',
    display: 'inline-block',
    backgroundColor: 'white',
    borderRadius: '20px',
    marginRight: '9px',
    verticalAlign: 'middle'
  },
  link: {
    flexGrow: '1'
  }
}))

export default function Logo(props) {
  const classes = useStyles()
  return(<React.Fragment>
    <Link style={props.style} className={classes.link} underline="none" component={RouterLink} color="inherit" to={props.to ? props.to : "/dashboard"}>
      <img src={logo} className={classes.logo} />
      <Typography color="textSecondary" className={classes.title} variant="h6" noWrap>
        <span className={classes.taas}>taas</span> {!props.hideOther && <span className={classes.other}>telegram as a service</span>}
      </Typography>
    </Link>
  </React.Fragment>)
}
