import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Link } from '@material-ui/core'
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
  },
  navpanel: {
    paddingTop: '16px'
  },
  navEl: {
    marginRight: '8px',
    "&> a": {
      padding: '8px 12px',
      borderRadius: 6,
      color: '#6b6b6b'
    },
    "&> a:hover": {
      textDecoration: 'none',
      backgroundColor: 'hsla(0, 0%, 96%, 1)',
    },
    "&> .is-active": {
      backgroundColor: '#efefef',
      color: '#6b6b6b'
    }
  }
}))

export default function DashboardNav(props) {
  const classes = useStyles();

  return(
    <div className={classes.navpanel}>
      <span className={classes.navEl}><Link component={NavLink} activeClassName="is-active" to='/dashboard'>Dashboard</Link></span>
      <span className={classes.navEl}><Link component={NavLink} activeClassName="is-active" to='/account'>Account</Link></span>
    </div>
  )
}