import React, { Component } from 'react';
import './App.scss';
// import '../node_modules/reset-css/reset.css';
import { Helmet } from "react-helmet";
import { API_BASE } from './consts.js'

class App extends Component {
  constructor() {
    super()
    this.state = {
    }
  }


  render() {
    const title = `TODO title`
    const metaDesc = `TODO meta description`
    return(<React.Fragment>
        <Helmet>
          <meta property="og:title" content={title} />
          <meta property="og:description" content={metaDesc} />
          <title>{title}</title>
          <meta name="description" content={metaDesc} />
        </Helmet>
      <div className="App">
        <div id="telegramWidgetContainer"></div>
      </div>
    </React.Fragment>
    );
  }
}

export default App;
