import React, { useState, Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import App from './App'
import NotFound from './NotFound'
import AppLayout from './components/AppLayout';
import LandingPage from './components/LandingPage';
import UserToolbar from './components/UserToolbar';
import Dashboard from './components/Dashboard';
import Account from './components/Account';
import Support from './components/Support';
import Docs from './components/Docs';
import { accessToken, username } from './utils/authorize'
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { Helmet } from "react-helmet";
import { WWW_BASE } from './consts';

const SignIn = lazy(() => import('./components/SignIn'));

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#111',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
      contrastText: '#fff'
    },
    // secondary: {
    //   light: '#0066ff',
    //   main: '#0044ff',
    //   // dark: will be calculated from palette.secondary.main,
    //   contrastText: '#ffcc00',
    // },
    secondary: {
      light: '#ffbb29',
      main: '#ffb10a',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffffff',
    },
    // error: will use the default color
  },
});

const dashboardRoute = '/dashboard'
const themeRoute = '/sites/:channelName/themes/:themeId'
const siteRoute = '/sites/:channelName/'

export default function Routes(props) {
  React.useEffect(() => {
    if (accessToken()) {
      window.zESettings = {
        webWidget: {
          offset: {
            vertical: '0px'
          },
          chat: {
            suppress: false,
            hideWhenOffline: false,
            tags: [username()]
          }
        }
      };
      const script = document.createElement("script");

      script.src = "https://static.zdassets.com/ekr/snippet.js?key=4841c305-017e-403f-891f-bc67b7cf5814";
      // script.async = true;
      script.id = 'ze-snippet';

      document.body.appendChild(script);
    }
  }, [accessToken()])

  const isAuthenticated = !!accessToken();

  return <Router>
    <Helmet>
      <title>Taas</title>
    </Helmet>
    <ThemeProvider theme={theme}>
      <Suspense fallback={<div>Loading...</div>}>
        {isAuthenticated ?
          <Switch>
            <AppLayout header={UserToolbar} path={dashboardRoute} exact component={Dashboard} />
            <AppLayout header={UserToolbar} path={'/account'} exact component={Account} />
            <AppLayout menuButton header={UserToolbar} path='/docs/:page?' component={Docs} routeName="docs" />
            <AppLayout header={UserToolbar} path='/support' component={Support} />
            <Redirect push from='/sign_in' to={dashboardRoute}/>
            <Redirect strict from='/' to={dashboardRoute}/>
            <Route component={NotFound} />
          </Switch>
          :
          <Switch>
            <AppLayout path='/sign_in' exact component={SignIn} />
            <AppLayout header={UserToolbar} path='/support' component={Support} />
            <AppLayout menuButton header={UserToolbar} path='/docs/:page?' component={Docs} routeName="docs" />
            <AppLayout path='/sign_up' exact component={SignIn} componentProps={{
              signUp: true
            }} />
            <Redirect strict from={dashboardRoute} to='/sign_in'/>
            { true && <Route path='/' component={() => {
              window.location.href = WWW_BASE;
              return null;
            }}/>}
            <Route component={NotFound} />
          </Switch>
        }
      </Suspense>
    </ThemeProvider>
  </Router>
}


export { themeRoute, siteRoute, dashboardRoute };
