import React from 'react'
import { API_BASE } from '../consts'
import {
  makeStyles, useTheme,
  Grid,
  Container,
  Typography,
  Hidden,
  Link,
  Drawer,
  Divider,
  List, ListItem, ListItemText, ListItemIcon, ListSubheader,
  IconButton,
  Table, TableCell, TableRow,
  Paper,
  Box,
} from '@material-ui/core'
import Playground from './Playground'
import { Route, Switch, Link as RouterLink, NavLink as NavRouterLink } from 'react-router-dom'

const drawerWidth = 360;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    maxWidth: '800px'
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  codeCard: {
    padding: theme.spacing(2, 2),
    marginBottom: theme.spacing(2),
  },
  endpoint: {
    display: 'block',
    padding: '16px',
    fontSize: theme.typography.subtitle1.fontSize,
    // backgroundColor: theme.palette.grey['700'],
    backgroundColor: "#303f9f",
    color: '#fff',
    marginBottom: '8px'
  },
  endpointMethod: {
    backgroundColor: "#1a237e",
    padding: '16px',
    marginRight: '16px',
    marginLeft: '-16px'
  },
  table: {
    backgroundColor: "#fff",
    marginBottom: '8px',
  },
  highlight: {
    color: '#303f9f'
  },
  list: {
    '& .active-nav': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText
    }
  },
  rlink: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    '&:visited': {
      color: theme.palette.secondary.light
    }
  }
}))

function DocLink(props) {
  const classes = useStyles();

  if (props.to) {
    return <RouterLink {...props} className={classes.rlink}>{props.children}</RouterLink>
  } else {
    return <Link color="secondary" {...props}>{props.children}</Link>
  }
}


const clientObject = [
  [ "id", "Client numeric ID" ],
  [ "auth_key", "Client API Key" ],
  [ "api_hash", "Telegram Api Hash" ],
  [ "api_id", "Telegram API Id" ],
  [ "current_usage.webhooks_count", "Current month webhooks usage stats" ],
  [ "current_usage.requests_count", "Current month requests usage stats" ],
  [ "is_bot", "If the client is a bot" ],
  [ "phone", "Phone" ],
  [ "state", "Client state (wait_phone_number, wait_code, wait_password, ready)" ],
  [ "created_at", "Created date time" ],
  [ "updated_at", "Updated date time" ],
  [ "use_message_database", "If using message database" ],
  [ "use_chat_info_database", "If using chat info database" ],
  [ "use_file_database", "If using file database" ],
  [ "version", "TDLib version used" ],
  [ "name", "A description name for the client" ],
]

export default function Docs(props) {
  const { container } = props;
  const theme = useTheme();
  const classes = useStyles();

  const handleDrawerClose = () => {
    props.layoutDispatch({type: 'setMenuOpen', payload: false});
  };

  const playground = (
    <Playground
      open={true}
      autoFocus={false}
      description="Insert your API Key in place of YOUR_API_KEY"
    />
  )

  const list = (
    <List className={classes.list}>
      <ListItem button component={NavRouterLink} exact activeClassName="active-nav" to="/docs">
        <ListItemText primary={'Introduction'} />
      </ListItem>
      <ListItem button component={NavRouterLink} exact activeClassName="active-nav" to="/docs/getting-started">
        <ListItemText primary={'Getting Started'} />
      </ListItem>
      <ListSubheader>How-To's</ListSubheader>
      <ListItem button component={NavRouterLink} exact activeClassName="active-nav" to="/docs/how-to/send-message-by-phone" className={classes.nested}>
        <ListItemText primary={'Send message by phone number'} />
      </ListItem>
      <ListSubheader>Dashboard</ListSubheader>
      <ListItem button component={NavRouterLink} exact activeClassName="active-nav" to="/docs/dashboard/clients" className={classes.nested}>
        <ListItemText primary={'Creating Clients'} />
      </ListItem>
      <ListSubheader>Client API</ListSubheader>
      <ListItem button component={NavRouterLink} exact activeClassName="active-nav" to="/docs/get-api-key" className={classes.nested}>
        <ListItemText primary={'Authentication'} />
      </ListItem>
      <ListItem button component={NavRouterLink} exact activeClassName="active-nav" to="/docs/client" className={classes.nested}>
        <ListItemText primary={'Calling Methods'} />
      </ListItem>
      <ListItem button component={NavRouterLink} exact activeClassName="active-nav" to="/docs/subscriptions" className={classes.nested}>
        <ListItemText primary={'Subscriptions (Webhooks)'} />
      </ListItem>
      <ListItem button component={NavRouterLink} exact activeClassName="active-nav" to="/docs/files" className={classes.nested}>
        <ListItemText primary={'Downloading Files'} />
      </ListItem>
      {/* <ListItem button component={NavRouterLink} exact activeClassName="active-nav" to="/docs/full-chat-history" className={classes.nested}>
           <ListItemText primary={'Full Chat History'} />
         </ListItem> */}
      <ListItem button component={NavRouterLink} exact activeClassName="active-nav" to="/docs/auth-code" className={classes.nested}>
        <ListItemText primary={'Authentication Code'} />
      </ListItem>
      <ListItem button component={NavRouterLink} exact activeClassName="active-nav" to="/docs/cloud-pwd" className={classes.nested}>
        <ListItemText primary={'Cloud Password'} />
      </ListItem>
      <ListItem button component={NavRouterLink} exact activeClassName="active-nav" to="/docs/errors" className={classes.nested}>
        <ListItemText primary={'Errors'} />
      </ListItem>
      <ListItem button component={NavRouterLink} exact activeClassName="active-nav" to="/docs/client/limits" className={classes.nested}>
        <ListItemText primary={'Limits'} />
      </ListItem>
      <ListSubheader>Admin REST API</ListSubheader>
      <ListItem button component={NavRouterLink} exact activeClassName="active-nav" to="/docs/admin-api/auth" className={classes.nested}>
        <ListItemText primary={'Authentication'} />
      </ListItem>
      <ListItem button component={NavRouterLink} exact activeClassName="active-nav" to="/docs/admin-api/clients" className={classes.nested}>
        <ListItemText primary={'Clients'} />
      </ListItem>
      {/*<ListItem button component={NavRouterLink} exact activeClassName="active-nav" to="/docs/faq">
         <ListItemText primary={'FAQ'} />
       </ListItem>
       <ListItem button component={NavRouterLink} exact activeClassName="active-nav" to="/docs/examples">
         <ListItemText primary={'Examples'} />
       </ListItem>*/}
    </List>
  )

  const drawer = (
   <div>
     <div className={classes.toolbar} />
     <Divider />
     {list}
     <Divider />
   </div>
 );

  return(
    <div className={classes.root}>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={props.menuOpen}
            onClose={handleDrawerClose}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <Switch>
          <Route path='/docs' exact>
            <Typography variant="h4" align="left" gutterBottom>Taas Documentation</Typography>
            <Typography variant="body1" gutterBottom>Taas lets you use Telegram programmatically.
              It runs the Telegram client on the cloud server and gives you access to it.
              After you create your first client, it will authorize the app and keep the client logged in.</Typography>
            <Typography variant="body1" gutterBottom>It is very similar to a Telegram bot, but bot's abilities are very limited &mdash; for example it can not get history of the chat or send the message to the unknown user and more. Taas can. Think of it as a full featured Telegram client. Under the hood it uses <DocLink href="https://github.com/tdlib/td">TDLib</DocLink> library v1.6.0 - you can use <DocLink
              href="https://core.telegram.org/tdlib/docs/td__api_8h.html">all the methods provided by it.</DocLink></Typography>
            <Typography variant="body1" gutterBottom>To start using Taas you need a phone number or a bot token and an API Key to sign requests. Follow <DocLink to="/docs/get-api-key">this guide</DocLink> to get the API key.</Typography>
            <Typography variant="body1" gutterBottom>To make requests to TDLib you need to use Client API endpoint. To receive data from TDLib - use Subscriptions (Webhooks) endpoint. Also you can download files via Files endpoint.</Typography>
            <Typography variant="body1" gutterBottom>If you want to To make requests to TDLib you need to use Client API endpoint. To receive data from TDLib - use Subscriptions (Webhooks) endpoint. Also you can download files via Files endpoint.</Typography>
            <Typography variant="body1" gutterBottom>If you are having any trouble please do not hesitate to <DocLink to="/support">contact us</DocLink>, we'll be happy to help. Subscribe to our Telegram channel <DocLink href="https://t.me/taas_news">@taas_news</DocLink> to receive news and updates.</Typography>
          </Route>
          <Route path='/docs/getting-started' exact>
            <Typography variant="h4" align="left" gutterBottom>Getting Started</Typography>
            <Typography variant="h6" gutterBottom>Making Telegram requests:</Typography>
            <Typography variant="body1" gutterBottom>
              <ol>
                <li>1. Create the client <DocLink to="/docs/get-api-key">in the Dashboard</DocLink> or with <DocLink to="/docs/admin-api/clients">Admin API</DocLink> and receive API Key</li>
                <li>2. Make requests to <DocLink to="/docs/client">Client API</DocLink> using received API Key. You can make any request from TDLib, the list of them is <DocLink href="https://core.telegram.org/tdlib/docs/td__api_8h.html">here</DocLink></li>
                <li>3. To receive updates &mdash; <DocLink to="/docs/subscriptions">create Webhooks</DocLink></li>
              </ol>
            </Typography>
          </Route>
          <Route path='/docs/how-to/send-message-by-phone' exact>
            <Typography variant="h4" align="left" gutterBottom>How to send message in Telegram API by phone number.</Typography>
            <Typography variant="body1" gutterBottom>If you would like to send anybody a message in Telegram knowing only phone number, with TaaS it's quite easy, just need to make three TDLib calls:</Typography>
            <Typography variant="body1" gutterBottom>
              <ol>
                <li>1. importContacts</li>
                <li>2. createPrivateChat</li>
                <li>3. sendMerssage</li>
              </ol>
            </Typography>
            <Typography variant="body1" gutterBottom>You can use the TaaS playground in Dashboard or below to try these requests. If you still don't have the API key, go to <DocLink to="/docs/dashboard/clients">Creating Clients</DocLink> to find how to create it.</Typography>
            <Typography variant="h6" gutterBottom>1. importContacts</Typography>
            <Typography variant="body1" gutterBottom>
              Make a request like this:
            </Typography>
            <Paper className={classes.codeCard}>
              <pre>
                <code>
                  {`{
  "api_key": "YOUR_API_KEY",
  "@type": "importContacts",
  "contacts": [{
      "first_name": "Jane",
      "last_name": "Doe",
      "phone_number": "+1555012345",
      "user_id": 0
  }]
}`}
                </code>
              </pre>
            </Paper>
            <Typography variant="body1" gutterBottom>
              You will receive response similar to this:
            </Typography>
            <Paper className={classes.codeCard}>
              <pre>
                <code>
                  {`{
  "@type": "importedContacts",
  "user_ids": [
    1234567
  ],
  "importer_count": [
    8
  ],
  "@extra": "f402e93d-0cc3-4765-ad54-2f5f5c593a9b"
}`}
                </code>
              </pre>
            </Paper>
            <Typography variant="h6" gutterBottom>2. createPrivateChat</Typography>
            <Typography variant="body1" gutterBottom>
              Take the user_id from previous response and use it in next request:
            </Typography>
            <Paper className={classes.codeCard}>
              <pre>
                <code>
                  {`{
    "api_key": "YOUR_API_KEY",
    "@type": "createPrivateChat",
    "user_id": 1234567
  }`}
                </code>
              </pre>
            </Paper>
            <Typography variant="h6" gutterBottom>3. sendMessage</Typography>
            <Typography variant="body1" gutterBottom>
              Send message using user_id as chat_id like this:
            </Typography>
            <Paper className={classes.codeCard}>
              <pre>
                <code>
                  {`{
    "api_key": "YOUR_API_KEY",
    "@type": "sendMessage",
    "chat_id": "1234567",
    "disable_notification": true,
    "input_message_content": {
      "@type": "inputMessageText",
      "disable_web_page_preview": false,
      "text": {
        "@type": "formattedText",
        "text": "Hello world"
      }
    }
  }`}
                </code>
              </pre>
            </Paper>
            <Typography variant="body1" gutterBottom>
              That is all.
            </Typography>
          </Route>
          <Route path='/docs/dashboard/clients' exact>
            <Typography variant="h4" align="left" gutterBottom>Creating Clients with Dashboard</Typography>
            <Typography variant="body1" gutterBottom>To start calling Telegram methods you need to create a Client.</Typography>
            <Typography variant="body1" gutterBottom>You will need:
              <ol>
                <li>1. Phone number or bot token</li>
                <li>2. api_id and api_hash from Telegram</li>
              </ol>
            </Typography>
            <Typography variant="h6" gutterBottom>Creating Client</Typography>
            <Typography variant="body1" gutterBottom>
              <ol>
                <li>1. Log in to the <DocLink to="/dashboard">dashboard</DocLink>.</li>
                <li>2. Press "New Client" and choose if you will use the phone or bot token. You will also need to specify api_id and api_hash - here are <DocLink href="https://core.telegram.org/api/obtaining_api_id">instructions how to get them</DocLink>. You need to do it just the first time. On the next step specify your account phone number or bot token.</li>
                <li>3. If you are using phone number, you will receive a security code from Telegram for that number &mdash; enter it in the next step.
              If code does not arrive or you experience any problems, you can try to create this client one more time. If it does not help, reach out to our support at <DocLink href="https://t.me/taas5">@taas5</DocLink></li>
                <li>4. If you are using cloud password, you will also need to specify it in a next step.</li>
              </ol>
              </Typography>
            <Typography variant="body1" gutterBottom>After that, your new API key will be shown in the dashboard.</Typography>
            <Typography variant="body1" gutterBottom>To try out various requests - open the API Playground in dashboard. You can click on the API key in the table to automatically paste it inside request editor.</Typography>
            <Typography variant="h6" gutterBottom>Deleting Client</Typography>
            <Typography variant="body1" gutterBottom>You can delete Client. When you delete the client, all the Telegram session data deleted will be deleted as well.</Typography>
            <Typography variant="body1" gutterBottom>In the <DocLink to="/dashboard">dashboard</DocLink>, click on the ... symbol near the key and click 'Delete'.</Typography>
          </Route>
          <Route path='/docs/get-api-key' exact>
            <Typography variant="h4" align="left" gutterBottom>Authentication</Typography>
            <Typography variant="body1" gutterBottom>Requests to Telegram authenticated with "auth_key" parameter in URL query string or in request body. To get the API Key, create the client.</Typography>
            <Typography variant="body1" gutterBottom>Client can be created in two ways:
              <ul>
                <li>• <DocLink to="/docs/dashboard/clients">Manually using dashboard</DocLink></li>
                <li>• <DocLink to="/docs/admin-api/clients">Programmatically with Admin API</DocLink></li>
              </ul>
            </Typography>
          </Route>
          <Route path='/docs/client/limits' exact>
            <Typography variant="h4" align="left" gutterBottom>Limits</Typography>
            <Typography variant="body1" gutterBottom>If you are running Free plan, you are limited to 15000 requests per account per month, when you reach the limit the requests will fail with 429 status code.</Typography>
          </Route>
          <Route path='/docs/errors' exact>
            <Typography variant="h4" align="left" gutterBottom>Errors</Typography>
            <Typography variant="h6" gutterBottom>Telegram errors</Typography>
            <Typography variant="body1" gutterBottom>If the request you are making produces an error &mdash; it will return 500 status code (or other if more appropriate, but not 200), the error description will be in the <code className={classes.highlight}>error</code> field of response JSON body.</Typography>
            <Typography variant="h6" gutterBottom>Telegram flood control</Typography>
            <Typography variant="body1" gutterBottom>If you are hitting Telegram server flood control &mdash; requests will return 429 status code and the body like this: <code className={classes.highlight}>{"{"} error: "Too Many Requests: retry after 78676" {"}"}</code></Typography>
            <Typography variant="h6" gutterBottom>Taas Limits</Typography>
            <Typography variant="body1" gutterBottom>If you are running Free plan, you are limited to 15000 requests per account per month, when you reach the limit the requests will fail with 429 status code. This also applies to other limitations introduced by our Plans.</Typography>
          </Route>
          <Route path='/docs/client' exact>
            <Typography variant="h4" align="left" gutterBottom>Endpoints: Client</Typography>
            <Typography variant="body1" gutterBottom>Client Endpoint is used to call all TDLib methods. If you want to send messages or get chat information, you use this endpoint.</Typography>
            <Typography variant="body1" gutterBottom>Requests should have header <code>Content-Type: application/json</code>. The body must be JSON encoded.</Typography>
            <span className={classes.endpoint}><span className={classes.endpointMethod}>POST</span>{API_BASE}/client</span>
            <Typography variant="h6" align="left" gutterBottom>Parameters</Typography>
            <Table className={classes.table} aria-label="simple table">
              <TableRow>
                <TableCell>api_key</TableCell>
                <TableCell>Your API Key</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>...</TableCell>
                <TableCell>Any additional parameter supplied will be passed to Telegram client to execute.</TableCell>
              </TableRow>
            </Table>
            {playground}
          </Route>
          <Route path='/docs/admin-api/auth' exact>
            <Typography variant="h4" align="left" gutterBottom>Admin API Authentication</Typography>
            <Typography variant="body1" gutterBottom>Add "Authorization" header to your request containing your Admin API key.</Typography>
            <Typography variant="body1" gutterBottom>You can find Admin API key here:</Typography>
            <Typography variant="body1" gutterBottom>
              <ol>
                <li>1. Go to the <DocLink to="/account">Account page</DocLink></li>
                <li>2. Find Admin API Key, and press "Show"</li>
              </ol>
            </Typography>
          </Route>
          <Route path='/docs/admin-api/clients' exact>
            <Typography variant="h4" align="left" gutterBottom>Clients</Typography>
            <Typography variant="body1" gutterBottom>Manage Telegram clients using Admin REST API.</Typography>
            <Typography variant="h5" align="left" gutterBottom>Creating</Typography>
            <span className={classes.endpoint}><span className={classes.endpointMethod}>POST</span>{API_BASE}/clients</span>
            <Typography variant="h6" align="left" gutterBottom>Parameters</Typography>
            <Table className={classes.table} aria-label="simple table">
              <TableRow>
                <TableCell>phone</TableCell>
                <TableCell>Phone number or bot token</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>is_bot_token</TableCell>
                <TableCell>Set to true if a bot token is in the phone field</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>api_id</TableCell>
                <TableCell>Your Telegram API_ID</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>api_hash</TableCell>
                <TableCell>Your Telegram API_HASH</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>use_message_database</TableCell>
                <TableCell>Optional. False by default, set to true if you know you really need it. Generally slows down requests if true in longer run.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>use_chat_info_database</TableCell>
                <TableCell>Optional. False by default, set to true if you know you really need it. Generally slows down requests if true in longer run.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>use_file_database</TableCell>
                <TableCell>Optional. False by default, set to true if you know you really need it. Generally slows down requests if true in longer run.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>version</TableCell>
                <TableCell>Optional. TDLib version: 1.6.0 or 1.7.0. Default is 1.7.0 since Jan 20 2021.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>name</TableCell>
                <TableCell>Optional. Any name to remember, for example, why you created this client.</TableCell>
              </TableRow>
            </Table>
            <Typography variant="h6" align="left" gutterBottom>Response</Typography>
            <Table className={classes.table} aria-label="simple table">
              {clientObject.map((i) => <TableRow>
                <TableCell>{i[0]}</TableCell>
                <TableCell>{i[1]}</TableCell>
              </TableRow>)}
            </Table>
            <Typography variant="h5" align="left" gutterBottom>Getting Clients list</Typography>
            <span className={classes.endpoint}><span className={classes.endpointMethod}>GET</span>{API_BASE}/clients</span>
            <Typography variant="h6" align="left" gutterBottom>Parameters</Typography>
            <Table className={classes.table} aria-label="simple table">
              {[
                [ "phone", "Optional. Filters the returned clients by phone" ],
              ].map((i) => <TableRow>
                <TableCell>{i[ 0 ]}</TableCell>
                <TableCell>{i[ 1 ]}</TableCell>
              </TableRow>)}
            </Table>
            <Typography variant="h6" align="left" gutterBottom>Response</Typography>
            <Typography variant="body1" gutterBottom>Same as in Create action</Typography>
            <Typography variant="h5" align="left" gutterBottom>Deleting Clients</Typography>
            <span className={classes.endpoint}><span className={classes.endpointMethod}>DELETE</span>{API_BASE}/clients/:id</span>
            <Typography variant="h6" align="left" gutterBottom>Parameters</Typography>
            <Table className={classes.table} aria-label="simple table">
              {[
                [ "id", "Client numeric ID" ],
              ].map((i) => <TableRow>
                <TableCell>{i[ 0 ]}</TableCell>
                <TableCell>{i[ 1 ]}</TableCell>
              </TableRow>)}
            </Table>
            <Typography variant="h6" align="left" gutterBottom>Response</Typography>
            <Typography variant="body1" gutterBottom>Same as in Create action</Typography>
          </Route>
          <Route path='/docs/subscriptions' exact>
            <Typography variant="h4" align="left" gutterBottom>Endpoints: Subscriptions</Typography>
            <Typography variant="body1" gutterBottom>If you want to set up webhooks for updates, you need to create subscription. Each time the update will come, the webhook will fire on the hook_url that you specify.</Typography>
            <Typography variant="h6" gutterBottom>Create Subscription</Typography>
            <span className={classes.endpoint}><span className={classes.endpointMethod}>POST</span>{API_BASE}/subscriptions</span>
            <Typography variant="h6" align="left" gutterBottom>Parameters</Typography>
            <Table className={classes.table} aria-label="simple table">
              <TableRow>
                <TableCell>api_key</TableCell>
                <TableCell>Your API Key</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>hook_url</TableCell>
                <TableCell>URL of a webhook</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>trigger</TableCell>
                <TableCell>The type of subscription. List of available triggers: <br />
                  <code className={classes.highlight}>new_message</code> (including from channels)<br />
                  <code className={classes.highlight}>new_private_message</code> (only private)<br />
                  <code className={classes.highlight}>message_send_acknowledged</code><br />
                  <code className={classes.highlight}>message_send_succeeded</code><br />
                  <code className={classes.highlight}>message_send_failed</code><br />
                  <code className={classes.highlight}>message_content</code><br />
                  <code className={classes.highlight}>message_edited</code><br />
                  <code className={classes.highlight}>message_views</code><br />
                  <code className={classes.highlight}>message_content_opened</code><br />
                  <code className={classes.highlight}>message_mention_read</code><br />
                  <code className={classes.highlight}>message_live_location_viewed</code><br />
                  <code className={classes.highlight}>new_chat</code><br />
                  <code className={classes.highlight}>chat_chat_list</code><br />
                  <code className={classes.highlight}>chat_title</code><br />
                  <code className={classes.highlight}>chat_photo</code><br />
                  <code className={classes.highlight}>chat_permissions</code><br />
                  <code className={classes.highlight}>chat_last_message</code><br />
                  <code className={classes.highlight}>chat_order</code><br />
                  <code className={classes.highlight}>chat_is_pinned</code><br />
                  <code className={classes.highlight}>chat_is_marked_as_unread</code><br />
                  <code className={classes.highlight}>chat_is_sponsored</code><br />
                  <code className={classes.highlight}>chat_has_scheduled_messages</code><br />
                  <code className={classes.highlight}>chat_default_disable_notification</code><br />
                  <code className={classes.highlight}>chat_read_inbox</code><br />
                  <code className={classes.highlight}>chat_read_outbox</code><br />
                  <code className={classes.highlight}>chat_unread_mention_count</code><br />
                  <code className={classes.highlight}>chat_notification_settings</code><br />
                  <code className={classes.highlight}>scope_notification_settings</code><br />
                  <code className={classes.highlight}>chat_action_bar</code><br />
                  <code className={classes.highlight}>chat_pinned_message</code><br />
                  <code className={classes.highlight}>chat_reply_markup</code><br />
                  <code className={classes.highlight}>chat_draft_message</code><br />
                  <code className={classes.highlight}>chat_online_member_count</code><br />
                  <code className={classes.highlight}>notification</code><br />
                  <code className={classes.highlight}>notification_group</code><br />
                  <code className={classes.highlight}>active_notifications</code><br />
                  <code className={classes.highlight}>have_pending_notifications</code><br />
                  <code className={classes.highlight}>delete_messages</code><br />
                  <code className={classes.highlight}>user_chat_action</code><br />
                  <code className={classes.highlight}>user_status</code><br />
                  <code className={classes.highlight}>user</code><br />
                  <code className={classes.highlight}>basic_group</code><br />
                  <code className={classes.highlight}>supergroup</code><br />
                  <code className={classes.highlight}>secret_chat</code><br />
                  <code className={classes.highlight}>user_full_info</code><br />
                  <code className={classes.highlight}>basic_group_full_info</code><br />
                  <code className={classes.highlight}>supergroup_full_info</code><br />
                  <code className={classes.highlight}>service_notification</code><br />
                  <code className={classes.highlight}>file</code><br />
                  <code className={classes.highlight}>file_generation_start</code><br />
                  <code className={classes.highlight}>file_generation_stop</code><br />
                  <code className={classes.highlight}>call</code><br />
                  <code className={classes.highlight}>user_privacy_setting_rules</code><br />
                  <code className={classes.highlight}>unread_message_count</code><br />
                  <code className={classes.highlight}>unread_chat_count</code><br />
                  <code className={classes.highlight}>option</code><br />
                  <code className={classes.highlight}>installed_sticker_sets</code><br />
                  <code className={classes.highlight}>trending_sticker_sets</code><br />
                  <code className={classes.highlight}>recent_stickers</code><br />
                  <code className={classes.highlight}>favorite_stickers</code><br />
                  <code className={classes.highlight}>saved_animations</code><br />
                  <code className={classes.highlight}>selected_background</code><br />
                  <code className={classes.highlight}>language_pack_strings</code><br />
                  <code className={classes.highlight}>connection_state</code><br />
                  <code className={classes.highlight}>terms_of_service</code><br />
                  <code className={classes.highlight}>users_nearby</code><br />
                  <code className={classes.highlight}>new_inline_query</code><br />
                  <code className={classes.highlight}>new_chosen_inline_result</code><br />
                  <code className={classes.highlight}>new_callback_query</code><br />
                  <code className={classes.highlight}>new_inline_callback_query</code><br />
                  <code className={classes.highlight}>new_shipping_query</code><br />
                  <code className={classes.highlight}>new_pre_checkout_query</code><br />
                  <code className={classes.highlight}>new_custom_event</code><br />
                  <code className={classes.highlight}>new_custom_query</code><br />
                  <code className={classes.highlight}>poll</code><br /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>chat_id</TableCell>
                <TableCell>Optional. Filter new messages by this chat ID, can be multiple separated by ",".</TableCell>
              </TableRow>
            </Table>
            <Typography variant="h6" gutterBottom>List Subscriptions</Typography>
            <span className={classes.endpoint}><span className={classes.endpointMethod}>GET</span>{API_BASE}/subscriptions</span>
            <Table className={classes.table} aria-label="simple table">
              <TableRow>
                <TableCell>api_key</TableCell>
                <TableCell>Your API Key</TableCell>
              </TableRow>
            </Table>
            <Typography variant="h6" gutterBottom>Delete Subscription</Typography>
            <Typography variant="body1" gutterBottom>To disable webhook, delete the subscription.</Typography>
            <span className={classes.endpoint}><span className={classes.endpointMethod}>DELETE</span>{API_BASE}/subscriptions/{'{'}trigger{'}'}</span>
            <Table className={classes.table} aria-label="simple table">
              <TableRow>
                <TableCell>api_key</TableCell>
                <TableCell>Your API Key</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>trigger</TableCell>
                <TableCell>The type of subscription.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>chat_id</TableCell>
                <TableCell>Optional. If subscription was created with a chat_id filter, specify it here.</TableCell>
              </TableRow>
            </Table>
            {playground}
          </Route>
          <Route path='/docs/files' exact>
            <Typography variant="h4" align="left" gutterBottom>Endpoints: Files</Typography>
            <Typography variant="body1" gutterBottom>Use this endpoint to download a Remote file. It can be image, or anything else. Outputs the file in a binary format.</Typography>
            <Typography variant="body1" gutterBottom>Add "embed=true" parameter if you want the image to be displayed inline, not downloaded as attachment in browser</Typography>
            <span className={classes.endpoint}><span className={classes.endpointMethod}>GET</span>{API_BASE}/client/files/{'{'}remote_file_id{'}'}?api_key=***(&embed=true)</span>
            <Typography variant="h6" align="left" gutterBottom>Parameters</Typography>
            <Table className={classes.table} aria-label="simple table">
              <TableRow>
                <TableCell>api_key</TableCell>
                <TableCell>Your API Key</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>embed</TableCell>
                <TableCell>If set, and true, displays image inline in browser rather than attachment (sets Content-Disposition header to `inline`)</TableCell>
              </TableRow>
            </Table>
          </Route>
          <Route path='/docs/full-chat-history' exact>
            <Typography variant="h4" align="left" gutterBottom>Endpoints: Full Chat History</Typography>
            <Typography variant="body1" gutterBottom>There is a convenient endpoint to receive full chat history. Outputs an array of messages.</Typography>
            <span className={classes.endpoint}><span className={classes.endpointMethod}>GET</span>{API_BASE}/client/get_full_chat_history</span>
            <Typography variant="h6" align="left" gutterBottom>Parameters</Typography>
            <Table className={classes.table} aria-label="simple table">
              <TableRow>
                <TableCell>api_key</TableCell>
                <TableCell>Your API Key.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>chat_id</TableCell>
                <TableCell>Chat ID to get history from.</TableCell>
              </TableRow>
            </Table>
            {playground}
          </Route>
          <Route path='/docs/auth-code' exact>
            <Typography variant="h4" align="left" gutterBottom>Endpoints: Authentication Code</Typography>
            <Typography variant="body1" gutterBottom>If you need to send the verification code for the client - use this endpoint. Normally, the code is asked in dialog when you create the client in a dashboard, but if something went wrong you can use this endpoint yourself.</Typography>
            <span className={classes.endpoint}><span className={classes.endpointMethod}>POST</span>{API_BASE}/client/code</span>
            <Typography variant="h6" align="left" gutterBottom>Parameters</Typography>
            <Table className={classes.table} aria-label="simple table">
              <TableRow>
                <TableCell>api_key</TableCell>
                <TableCell>Your API Key.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>code</TableCell>
                <TableCell>Authentication Code.</TableCell>
              </TableRow>
            </Table>
          </Route>
          <Route path='/docs/cloud-pwd' exact>
            <Typography variant="h4" align="left" gutterBottom>Endpoints: Cloud Password</Typography>
            <Typography variant="body1" gutterBottom>If you have cloud password enabled, you can send it using this endpoint. Normally, the password is asked in dialog when you create the client in a dashboard, but if something went wrong you can use this endpoint yourself. Taas does not store your cloud password.</Typography>
            <span className={classes.endpoint}><span className={classes.endpointMethod}>POST</span>{API_BASE}/client/password</span>
            <Typography variant="h6" align="left" gutterBottom>Parameters</Typography>
            <Table className={classes.table} aria-label="simple table">
              <TableRow>
                <TableCell>api_key</TableCell>
                <TableCell>Your API Key.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>password</TableCell>
                <TableCell>Your cloud password.</TableCell>
              </TableRow>
            </Table>
          </Route>
          <Route path='/docs/faq' exact>
            <Typography variant="h4" align="left" gutterBottom>Endpoints: Full Chat History</Typography>
            <Typography variant="body1" gutterBottom>If for some reason you need to send the verification code for the client again - use this endpoint</Typography>
            <span className={classes.endpoint}><span className={classes.endpointMethod}>POST</span>{API_BASE}/client/code</span>
            <Typography variant="h6" align="left" gutterBottom>Parameters</Typography>
            <Table className={classes.table} aria-label="simple table">
              <TableRow>
                <TableCell>api_key</TableCell>
                <TableCell>Your API Key.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>code</TableCell>
                <TableCell>Authentication Code.</TableCell>
              </TableRow>
            </Table>
          </Route>
        </Switch>
        <Hidden smUp>
          <Typography variant="body1">Contents</Typography>
          {list}
        </Hidden>
     </main>
    </div>
  )
}
