export function authorize(accessToken, username) {
  window.localStorage.setItem('taas.accessToken', accessToken)
  window.localStorage.setItem('taas.username', username)
}

export function accessToken() {
  return window.localStorage.getItem('taas.accessToken')
}

export function username() {
  return window.localStorage.getItem('taas.username')
}

export function signOut() {
  window.localStorage.removeItem('taas.accessToken')
  window.localStorage.removeItem('taas.username')
}
