import React from 'react'
import { API_BASE } from '../consts'
import { accessToken } from '../utils/authorize'
import { ButtonGroup, Button, CircularProgress, Icon, Link, makeStyles, Paper, Typography } from '@material-ui/core'
import MetricsGraphics from 'react-metrics-graphics';
import 'metrics-graphics/dist/metricsgraphics.css';
import * as d3shape from "d3-shape";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
  }
}))

export default function ApplicationMetrics(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState();
  const [metrics, setMetrics] = React.useState();
  const [frame, setFrame] = React.useState('1d');
  const [error, setError] = React.useState();
  const { app_id } = props;
  const width = 600, height = 180;

  React.useEffect(() => {
      setLoading(true)
      setError(null)
      let path = app_id ? `/metrics/${app_id}` : `/metrics`
      fetch(`${API_BASE}${path}?frame=${frame}`, {
        method: 'get',
        headers: {
          'Accept': 'application/json',
          'Authorization': accessToken()
        }
      }).then(res => {
        return res.json()
      }).then(res => {
        // setMetrics(res[0].values.map((vv) =>({date: (new Date(vv[0] * 1000)), value: vv[1] }) ) )
        let newMetrics = {};
        if (res.requests.length > 0) {
          newMetrics.requests = res.requests.map((v) => v.values.map(vv => ({ date: (new Date(vv[ 0 ] * 1000)), value: parseInt(vv[ 1 ]) })))
        } else {
          newMetrics.requests = [[[]]]
        }

        if (res.times.length > 0 && res.times[0].length > 0) {
          newMetrics.times = res.times.map((v) => v[ 0 ].values.map(vv => ({ date: (new Date(vv[ 0 ] * 1000)), value: vv[ 1 ] == "NaN" ? 0 : parseFloat(parseFloat(vv[ 1 ]).toFixed(2)) })))
        } else {
          newMetrics.times = [[[]]]
        }

        if (res.spaces.length > 0) {
          newMetrics.spaces = res.spaces.map((v) => v.values.map(vv => ({ date: (new Date(vv[ 0 ] * 1000)), value: parseInt(vv[ 1 ]) })))
        } else {
          newMetrics.spaces = [[[]]]
        }

        setMetrics(newMetrics)
      })
      .finally(() => {
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setError(err)
      })
  }, [frame])

  return(
    <React.Fragment>
      <div>
        {window.taasCurrentPlan == "Free" && <div>
          <div style={{marginTop: '8px'}}>
            <Typography variant="body2" style={{ margin: "10px" }}>Detailed client metrics are unavailable at Free plan. Please <Link href="/account" color="secondary">upgrade</Link> to Basic or higher to view them. <Link href="/docs/metrics">More information about metrics</Link></Typography>
            <Typography align="center" variant="h6">Requests & Errors count</Typography>
            <div
              style={{
                width: '100%',
                height: 100,
                border: '1px solid #ccc'
              }}
            ></div>
            <Typography align="center" variant="h6">Request time</Typography>
            <div
              style={{
                width: '100%',
                height: 100,
                border: '1px solid #ccc'
              }}
            ></div>
            <Typography align="center" variant="h6">Disk space</Typography>
            <div
              style={{
                width: '100%',
                height: 100,
                border: '1px solid #ccc'
              }}
            ></div>
          </div>

        </div>}
        {window.taasCurrentPlan != "Free" && <div>
          {!app_id && <div>
            <Typography variant="body2">You can also view metrics per client by clicking <Icon>insights</Icon> button on client row</Typography>
          </div>}
          {!metrics && !error && <div>
            <div
              style={{ textAlign: 'center', width: "100%", height: height - 80}}
            >
              <CircularProgress style={{marginTop: '7%'}} />
            </div>
            <div
              style={{ textAlign: 'center', width: "100%", height: height - 80 }}
            ><CircularProgress style={{marginTop: '7%'}} /></div>
            <div
              style={{ textAlign: 'center', width: "100%", height: height - 80 }}
            ><CircularProgress style={{marginTop: '7%'}} /></div>
          </div>
          }
          {error && <span>Error loading metrics: {error.message}</span>}
          {metrics && <div>
            <div>
              <ButtonGroup>
                <Button size="small" disabled={frame == '1w'} onClick={() => setFrame("1w")}>1w</Button>
                <Button size="small" disabled={frame == '1d'} onClick={() => setFrame("1d")}>1d</Button>
                <Button size="small" disabled={frame == '6h'} onClick={() => setFrame("6h")}>6h</Button>
                <Button size="small" disabled={frame == '3h'} onClick={() => setFrame("3h")}>3h</Button>
                <Button size="small" disabled={frame == '1h'} onClick={() => setFrame("1h")}>1h</Button>
              </ButtonGroup>
              {loading && <CircularProgress size="16px" />}
            </div>
            <div id="m_r">
              <MetricsGraphics
                title="Requests & Errors count"
                description="Application requests and failures count"
                data={metrics.requests}
                full_width
                missing_text="No data"
                show_missing_background
                height={height}
                legend={[ 'Requests', 'Errors' ]}
                x_accessor="date"
                colors={[ '#6667ec', 'tomato' ]}
                y_accessor="value"
                aggregate_rollover={true}
                legend_target="#m_r .target"
                show_tooltips={false}
                animate_on_load
                interpolate={d3shape.curveStepAfter}
              />
              <span className="target"></span>
            </div>
            <div id="m_rt">
              <MetricsGraphics
                title="Request time"
                data={metrics.times}
                full_width
                height={height}
                yax_units={"s"}
                yax_units_append
                legend={[ '50th percentile', '95th percentile', '99th percentile' ]}
                x_accessor="date"
                colors={[ '#6667ecff', '#6667ecaa', '#6667ec55' ]}
                y_accessor="value"
                aggregate_rollover={true}
                legend_target="#m_rt .target"
                show_tooltips={false}
                animate_on_load
                interpolate={d3shape.curveStepAfter}
              />
              <span className="target"></span>
            </div>
            <div id="m_d">
              <MetricsGraphics
                title="Disk space"
                data={metrics.spaces}
                full_width
                height={height}
                x_accessor="date"
                y_accessor="value"
                aggregate_rollover={true}
                legend_target="#md .target"
                show_tooltips={false}
                animate_on_load
                interpolate={d3shape.curveStepAfter}
              />
              <span className="target"></span>
            </div>
          </div>}
        </div>}
      </div>
    </React.Fragment>
  )
}
