import React from 'react';
import { Route, Link as RouterLink } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline';
import LinearProgress from '@material-ui/core/LinearProgress';
import Fade from '@material-ui/core/Fade';

function LayoutReducer(state, action) {
  switch (action.type) {
    case 'setLoading':
      return {...state, loading: true};
    case 'setLoaded':
      return {...state, loading: false};
    case 'setMenuOpen':
      return {...state, menuOpen: action.payload};
    default:
      throw new Error();
  }
}

export default function AppLayout(props) {
  const {component: Component, header: Header, componentProps, routeName, ...rest} = props
  const [state, dispatch] = React.useReducer(LayoutReducer, {loading: null, menuOpen: false})

  function handleMenuButtonClick() {
    dispatch({type: 'setMenuOpen', payload: !state.menuOpen})
  }

  return (
    <Route {...rest} render={matchProps => (
      <div className="DefaultLayout">
        <CssBaseline />
        {Header && <Header {...componentProps} routeName={routeName} menuButton={props.menuButton} onMenuButtonCLick={handleMenuButtonClick} />}
        <Fade in={state.loading}><LinearProgress style={{position: 'absolute', left: '0', right: '0'}}/></Fade>
        <Component {...matchProps} {...componentProps} layoutDispatch={dispatch} {...state} />
      </div>
    )} />
  )
}
