import React from 'react';
import { hydrate, render } from 'react-dom';
import Routes from './routes';
import Rollbar from 'rollbar';
import 'whatwg-fetch';

const rollbar = new Rollbar({
  accessToken: "a635c456204a4da5b0d3db1509a1ebb4",
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: (process.env.NODE_ENV === 'production'),
  payload: {
      environment: process.env.NODE_ENV
  }
})

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  render(
    <Routes />,
    rootElement
  );
  // hydrate(
  //   <Routes />,
  //   rootElement
  // );
} else {
  render(
    <Routes />,
    rootElement
  );
}

if (module.hot) {
  module.hot.accept('./routes', () => {
    const NextApp = require('./routes').default
    render(
      <NextApp />,
      rootElement
    )
  })
}
