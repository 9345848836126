import React from 'react'
import { Container, Grid, Typography, makeStyles, Button, useMediaQuery } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },
  heroContent: {
    padding: theme.spacing(2, 0, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8, 0, 6),
    },
    '& p': {
      padding: theme.spacing(1, 0, 2),
    }
  },
}))
export default function LandingPage(props) {
  const classes = useStyles();
  const upSm = useMediaQuery(theme => theme.breakpoints.up('sm'));

  return <div>
    <Container maxWidth="lg" component="main" className={classes.heroContent}>
      <Typography component="h1" variant={upSm ? "h2" : "h5"} align="center" color="textPrimary" gutterBottom>
        Создайте веб-сайт на основе телеграм-канала бесплатно за один клик
      </Typography>
      <Typography variant={upSm ? "h5" : "p"} align="center" color="textSecondary" component="p" gutterBottom>
        Превратите телеграм канал в веб-сайт. Здесь картинка иллюстрация
      </Typography>
      <Grid container spacing={2} justify="center">
        <Grid item>
          <Button component={RouterLink} to="/dashboard" color="primary" variant="contained">Создать сайт</Button>
        </Grid>
      </Grid>
    </Container>
  </div>
}
