import React from 'react'
import { makeStyles, Container, Grid, Typography, LinearProgress, Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Paper,
  Collapse,
  Link
} from '@material-ui/core'
import { API_BASE } from '../consts'
import DashboardNav from './DashboardNav';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { monthToString } from '../utils/time'
import { accessToken } from '../utils/authorize'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 0,
  },
  expandIcon: {
    verticalAlign: 'middle'
  },
  field: {
    fontWeight: '500'
  },
  header: {
    marginTop: 8 * 6,
    marginBottom: 8,
    // paddingLeft: '16px !important'
  },
  row: {
    marginTop: 0,
    marginBottom: 0,
    paddingBottom: '16px !important',
    paddingTop: '16px !important',
    cursor: 'pointer',
    paddingLeft: '16px !important',
    borderBottom: '1px solid #ccc',
    '&:last-child': {
      borderBottom: 'none',
    },
    '&:hover': {
      backgroundColor: '#eee',
      borderRadius: 32,
      borderBottom: 'none'
    }
  },
  planCard: {
    padding: theme.spacing(2, 2)
  },
  statusField: {
    color: '#666',
    "& > .status-Paid": {
      color: 'green'
    },
    "& > .status-Not-Paid": {
      color: 'red'
    }
  }
}))

export default function Account(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true)
  const [usage, setUsage] = React.useState([]);
  const [planModalOpen, setPlanModalOpen] = React.useState(false);
  const [currentPlan, setCurrentPlan] = React.useState();
  const [modalPlan, setModalPlan] = React.useState();
  const [openedRow, setOpenedRow] = React.useState();
  const [showKey, setShowKey] = React.useState();

  const handlePlanChange = (e) => {
    setModalPlan(e.target.value)
  }

  React.useEffect(() => {
    fetch(`${API_BASE}/usage.json`, {
      headers: {
        'Authorization': accessToken()
      }
    }).then(res => res.json())
      .then(res => {
        setUsage(res)
        setLoading(false)
      })

    fetch(`${API_BASE}/plan.json`, {
      headers: {
        'Authorization': accessToken()
      }
    }).then(res => res.json())
      .then(res => {
        setCurrentPlan(res.plan)
        setModalPlan(res.plan)
      })
  }, [])

  const handlePlanSubmit = () => {
    fetch(`${API_BASE}/plan.json`, {
      method: 'POST',
      headers: {
        'Authorization': accessToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        plan: modalPlan
      })
    }).then(res => res.json())
      .then(res => {
        setCurrentPlan(modalPlan)
        setPlanModalOpen(false)
      })
  }

  return(
    <Container maxWidth="md">
      <DashboardNav />
      <Grid container spacing={2}>
        <Grid item xs={12} className={classes.header}>
          <Typography variant="h5" className={classes.field} gutterBottom>Admin API Key</Typography>
          <div>
            <Typography variant="body1" style={{ paddingTop: '8px' }} gutterBottom>
              Admin API Key: {!showKey && <Button color="inherit" onClick={() => setShowKey(true)}>Show</Button>}{showKey && accessToken()}</Typography>
          </div>
          <Typography variant="h5" className={classes.field} gutterBottom>Plan</Typography>
          <Typography variant="p">{currentPlan}  <Button onClick={() => setPlanModalOpen(true)}>Change</Button></Typography>
          <Typography variant="body2">Account is charged at the end of the period. Administrator will contact you by Telegram and issue an invoice. You will have a certain amount of time to pay, so don't worry about disruptions.</Typography>
        </Grid>

        <Grid item xs={12} className={""}>
          <Typography variant="h5" className={classes.field}>Usage</Typography>
        </Grid>
        {loading && <Grid item xs={12}>
          <LinearProgress indeterminate />
        </Grid>}
        {usage.length > 0 && <Grid container>
          <Grid container item style={{ marginTop: '' }} spacing={2}>
            <Grid item xs={2}><div className={classes.field} style={{ marginTop: '16px', marginLeft: '8px', marginBottom: '-8px' }}>Month</div></Grid>
            <Grid item xs={3}><div className={classes.field} style={{ marginTop: '16px', marginLeft: '0', marginBottom: '-8px' }}>Requests Count</div></Grid>
            <Grid item xs={3}><div className={classes.field} style={{ marginTop: '16px', marginLeft: '0', marginBottom: '-8px' }}>Webhooks Count</div></Grid>
            <Grid item xs={2}><div className={classes.field} style={{ marginTop: '16px', marginLeft: '0', marginBottom: '-8px' }}>Total</div></Grid>
            <Grid item xs={1}><div className={classes.field} style={{ marginTop: '16px', marginLeft: '0', marginBottom: '-8px' }}>Cost</div></Grid>
            <Grid item xs={1}><div className={classes.field} style={{ marginTop: '16px', marginLeft: '0', marginBottom: '-8px' }}>Status</div></Grid>
          </Grid>
          {usage.map(usage => <React.Fragment><Grid onClick={() => setOpenedRow(openedRow == usage.month ? null : usage.month)} item xs={12} container className={classes.row}>
            <Grid item xs={2}>{openedRow == usage.month ? <ExpandLess className={classes.expandIcon} /> : <ExpandMore className={classes.expandIcon} />}{monthToString(usage.month)}</Grid>
            <Grid item xs={3}>{usage.requests_count_sum}</Grid>
            <Grid item xs={3}>{usage.webhooks_count_sum}</Grid>
            <Grid item xs={2}>{usage.requests_count_sum + usage.webhooks_count_sum}</Grid>
            <Grid item xs={1}>${usage.price_sum}</Grid>
            <Grid item xs={1} className={classes.statusField}><span className={`status-${usage.status && usage.status.replace(' ', '-')}`}>{usage.status}</span></Grid>
          </Grid>
            <Collapse in={openedRow == usage.month} style={{width: '100%'}} >
              <Grid container item style={{ marginTop: '' }} spacing={2}>
                <Grid item xs={3}><div className={classes.field} style={{ marginTop: '16px', marginLeft: '8px', marginBottom: '-8px' }}>Item</div></Grid>
                <Grid item xs={3}><div className={classes.field} style={{ marginTop: '16px', marginLeft: '8px', marginBottom: '-8px' }}>Tariff</div></Grid>
                <Grid item xs={3}><div className={classes.field} style={{ marginTop: '16px', marginLeft: '0', marginBottom: '-8px' }}>Amount</div></Grid>
                <Grid item xs={3}><div className={classes.field} style={{ marginTop: '16px', marginLeft: '0', marginBottom: '-8px' }}>Total</div></Grid>
              </Grid>
              {usage.details.map(detail => <Grid item container xs={12} className={classes.row}>
                <Grid item xs={3}>{detail.name}</Grid>
                <Grid item xs={3}>{detail.tariff == 0 ? 'free' : `$${detail.tariff} per month`}</Grid>
                <Grid item xs={3}>{detail.amount}</Grid>
                <Grid item xs={3}>{detail.price == 0 ? 'free' : `$${detail.price}`}</Grid>
              </Grid>
              )}
            </Collapse>
          </React.Fragment>)}
          </Grid>}
      </Grid>
      <Dialog maxWidth="md" open={planModalOpen} onClose={() => { setPlanModalOpen(false); setModalPlan(currentPlan) }} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Change Plan</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You only pay for the time the plan is selected on per-minute basis. Payment is at the end of the month.
            <br/>
            {/* 1GB additional traffic - $0.028, 1 GB additional disk space - $0.056 */}
          </DialogContentText>
          <RadioGroup aria-label="plan" name="plan" value={modalPlan} onChange={handlePlanChange}>
            <Grid container spacing={2}>
              <Grid item sm={3}>
                <Paper onClick={() => setModalPlan('Free')} className={classes.planCard}>
                  <FormControlLabel value="Free" control={<Radio color="primary" />} label="Free" />
                  <Typography variant="body2">Maximum 12000 requests per month</Typography>
                  <Typography variant="body2">1 client maximum</Typography>
                </Paper>
              </Grid>
              <Grid item sm={3}>
                <Paper onClick={() => setModalPlan('Hobby')} className={classes.planCard}>
                  <FormControlLabel value="Hobby" control={<Radio color="primary" />} label="Hobby" />
                  <Typography variant="body2">$39.00 per month</Typography>
                  <Typography variant="body2">1 client maximum</Typography>
                  <Typography variant="body2">120 000 requests included</Typography>
                  <Typography variant="body2">low performance</Typography>
                  {/* <Typography variant="body2">10 GB disk space, 10 GB traffic included</Typography> */}
                </Paper>
              </Grid>
              <Grid item sm={3}>
                <Paper onClick={() => setModalPlan('Basic')} className={classes.planCard}>
                  <FormControlLabel value="Basic" control={<Radio color="primary" />} label="Basic" />
                  <Typography variant="body2">$98.00 per month</Typography>
                  <Typography variant="body2">10 clients maximum</Typography>
                  <Typography variant="body2">unlimited requests</Typography>
                  <Typography variant="body2">standard performance</Typography>
                  {/* <Typography variant="body2">100 GB disk space, 100 GB traffic included</Typography> */}
                </Paper>
              </Grid>
              <Grid item sm={3}>
                <Paper onClick={() => setModalPlan('Standard')} className={classes.planCard}>
                  <FormControlLabel value="Standard" control={<Radio color="primary" />} label="Standard" />
                  <Typography variant="body2">$198.00 per month</Typography>
                  <Typography variant="body2">100 clients maximum</Typography>
                  <Typography variant="body2">unlimited  requests</Typography>
                  <Typography variant="body2">high performance</Typography>
                  {/* <Typography variant="body2">100 GB disk space, 100 GB traffic included</Typography> */}
                </Paper>
              </Grid>
              <Grid item sm={3}>
                <Paper className={classes.planCard}>
                  <FormControlLabel value="Unlimited" control={<Radio disabled/>} label="Unlimited" />
                  <Typography variant="body2">Unlimited clients and requests</Typography>
                  <Typography variant="body2">Top level security and privacy</Typography>
                  <Typography variant="body2">Priority performance</Typography>
                  {/* <Typography variant="body2">100 GB disk space, 200 GB traffic included</Typography> */}
                  <Typography variant="body2">Contact us at Telegram <Link color="secondary" href="https://t.me/taas5">@taas5</Link> to select this plan.</Typography>
                </Paper>
              </Grid>
            </Grid>
          </RadioGroup>
          <Typography variant="body2">All limits (except Requests) are soft - you can scale the tariff already after you get over the limit, with no service interruptions.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePlanSubmit} color="primary">
            ОК
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}
