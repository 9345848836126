import React from 'react'
import { Route, Link as RouterLink } from 'react-router-dom'
import { withRouter } from 'react-router'
import { AppBar, Toolbar, Typography, makeStyles, Link, Button, IconButton } from '@material-ui/core'
import Logo from './Logo'
import { signOut, accessToken } from '../utils/authorize'
import { WWW_BASE } from '../consts'
import MenuIcon from '@material-ui/icons/Menu';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: theme.mixins.toolbar,
  menuButton: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  }
}))

function UserToolbar(props) {
  const classes = useStyles();
  const isAuthenticated = !!accessToken();

  function handleSignOut() {
    signOut()
    window.location = '/'
  }

  function handleMenuButtonClick() {
    props.onMenuButtonCLick()
  }

  return <div className={classes.root}>
      <AppBar color="default" elevation={0} position="fixed" className={classes.appBar}>
        <Toolbar>
          {props.menuButton && <IconButton
            color="inherit"
            variant="outlined"
            aria-label="open drawer"
            edge="start"
            onClick={handleMenuButtonClick}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          }
          <Logo />
          {props.routeName == "docs" && <Button className={classes.button} component={RouterLink} to={`/dashboard`} color="primary">Dashboard</Button>}
          <Button className={classes.button} component={RouterLink} to={`/support`} color="primary">Support</Button>
          {props.routeName !== "docs" && <Button className={classes.button} component={RouterLink} to={`/docs`} color="primary">Docs</Button>}
          {isAuthenticated && <Button className={classes.button} onClick={handleSignOut} color="primary">Log Out</Button>}
          {!isAuthenticated && <Button component={RouterLink} to="/sign_in" color="primary">Sign In</Button>}
        </Toolbar>
      </AppBar>
      <div className={classes.toolbar} />
    </div>
}

export default withRouter(UserToolbar)
