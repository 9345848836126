let api_base = 'http://localhost:3001'
if (process.env.REACT_APP_RELEASE)
  api_base = window.location.hostname.match('tgtgtg.ru') ? 'https://api.tgtgtg.ru' : 'https://api.tgtgtg.ru'
else if (process.env.REACT_APP_PRERELEASE)
  api_base = 'https://api-staging.tgtgtg.ru'
else if (process.env.REACT_APP_LOCALRELEASE)
  api_base = 'http://localhost:3001'

let www_base = 'http://localhost:3001'
if (process.env.REACT_APP_RELEASE)
  www_base = window.location.hostname.match('tgtgtg.ru') ? 'https://www.tgtgtg.ru': 'https://www.tgtgtg.ru'
else if (process.env.REACT_APP_PRERELEASE)
  www_base = 'https://www-staging.tgtgtg.ru'
else if (process.env.REACT_APP_LOCALRELEASE)
  www_base = 'http://localhost:3001'

let bot_username = 'taas_authentication_dev_bot'
if (process.env.REACT_APP_RELEASE)
  bot_username = 'tgtgtgru_bot'
else if (process.env.REACT_APP_PRERELEASE)
  bot_username = 'x18321236_bot'


export const WWW_BASE = www_base
export const API_BASE = api_base
export const BOT_USERNAME = bot_username
